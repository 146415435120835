.upload-logo {
    min-height: 100vh;
    margin-top: 8vh;
    padding-top: 5rem;
    background: var(--color-light);
    color: var(--color-dark);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.upload-logo .form {
    margin: 1rem;
}

.upload-logo .logo-list {
    position: relative;
    width: 100%;
    padding: 1rem 15rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.upload-logo .logo-list .logo-item {
    border: 1px solid rgba(var(--rgb-dark-alt),0.2);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.upload-logo .logo-list .logo-item img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.upload-logo .logo-list .logo-item i {
    margin-top: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
}