.case {
    min-height: 100vh;
    background: var(--color-light);
    margin-top: 8vh;
    width: 100vw;
    overflow-x: hidden;
}

.case .container {
    padding: 5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.case .container .client-description {
    margin: 2rem 1rem;
}

@media only screen and (min-width: 1210px){
    .case .container {
        padding: 5rem 10rem;
    }
    .case .container .client-description {
        margin: 2rem 10rem;
    }
}

.case .container .case-videos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.case .container .case-videos .item {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
    min-width: 56vw;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(var(--rgb-dark),0.3);
    text-align: right;
}

.case .container .case-videos .item:nth-child(odd) {
    flex-direction: row;
    text-align: left;
}

.case .container .case-videos .item:last-child {
    border: none;
}

.case .container .case-videos .item .video-information {
    width: 50%;
    min-height: 100%;
    margin: 2rem;
}

.case .container .case-videos .item .video-title {
    color: var(--color-dark);
    margin-bottom: 1rem;
    padding: 0rem 1rem;
}

.case .container .case-videos .item .video-description {
    width: 100%;
    font-size: 1rem;
    padding: 0rem 1rem;
}


.case .container .case-videos iFrame {
    margin: 2rem;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    height: 250px;
    width: 500px;
}

@media only screen and (max-width: 1210px) {
    .case .container .case-videos .item {
        flex-direction: column-reverse;
        margin-bottom: 2rem;
        border-bottom: 1px solid rgba(var(--rgb-dark),0.3);
        text-align: center;
    }
    .case .container .case-videos .item:nth-child(odd) {
        flex-direction: column-reverse;
        text-align: center;
    }
    .case .container .case-videos .item .video-information {
        width: 100%;
        margin: 1rem 0rem;
        font-size: 0.8rem;
    }
    .case .container .case-videos .item .video-description {
        padding: 0rem 2rem;
        text-align: left;
    }
    .case .container .case-videos iFrame {
        height: 180px;
        width: 360px;
    }
}