.upload {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload button {
    margin: 0rem 1.5rem;
    padding: 1rem 2rem;
    border: none;
    background: var(--color-dark);
    color: var(--color-light);
    font-size: 1rem;
    font-weight: 600;
    text-transform: lowercase;

    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    
    cursor: pointer;
}

.upload button:hover {
    background: rgba(var(--rgb-dark),0.8);
}

.upload button.disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.upload button.disabled:hover {
    background: var(--color-dark);
}

.upload h1 {
    text-align: center;
    color: var(--color-dark);
}