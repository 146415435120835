.contact {
    min-height: 60vh;
    padding: 0 5vw;
    background: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact .address a {
    width: 100%;
    margin: 1rem 0rem;
    font-size: 1.5rem;
    color: rgba(var(--rgb-dark-alt),0.5);
    
    cursor: pointer;
}

.contact .address a:hover {
    width: 100%;
    margin: 1rem 0rem;
    font-size: 1.5rem;
    color: rgba(var(--rgb-dark-alt),0.35);
    
    cursor: pointer;
}

.contact .address a i {
    margin-right: 1rem;
}

@media only screen and (max-width: 1210px) {
    .contact .address a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .contact .address a i {
        margin-right: 0rem;
        margin: 1rem;
    }
}