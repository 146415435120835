.construction {
    height: 100vh;
    background: var(--color-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.construction img {
    margin: 5rem;
    width: 80%;
}

@media only screen and (min-width: 910px) {
    .construction img {
        margin: 5rem;
        width: 20%;
    }
}

.construction button {
    padding: 0.5rem 1rem;
    border: none;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.construction button.developer-entry {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.construction .construction-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    min-height: 40%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    background: rgba(var(--rgb-light),0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);

    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.construction .construction-modal.inactive {
    display: none;
}

.construction .construction-modal input {
    padding: 1rem;
    border: none;
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.construction .construction-modal .modal-buttons {
    width: 50%;
    display: flex;
    justify-content: space-around;
}