.upload-video {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-video .form {
    position: relative;
    min-width: 40%;
    height: 60vh;
}

@media (max-width: 1210px) {
    .upload-video .form {
        min-width: 60%;
    }
}
@media (max-width: 910px) {
    .upload-video .form {
        min-width: 80%;
    }
}

.upload-video .form select {
    padding: 1rem;
}

.upload-video .form h1,
.upload-video .form h2 {
    text-align: center;
    color: var(--color-dark);
}

.upload-video .form .search-container {
    display: flex;
    padding: 1rem;
}

.upload-video .form .search-container .search {
    border: 1px solid var(--color-dark-static);
    height: 3rem;
    width: 90%;
    padding: 1rem;
    font-size: 20px;

    border-right: none;

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.upload-video .form .search-container .search-icon {
    border: 1px solid var(--color-dark-static);
    height: 3rem;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    border-left: none;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.upload-video .form .client-selection {
    position: relative;
    height: 70%;
    overflow-y: scroll;
    padding: 0.5rem;
    border: 3px solid rgba(var(--rgb-dark),0.2);

    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.upload-video .form .client-selection .add-client {
    text-align: center;
}

.upload-video .form .client-selection .client-item {
    margin: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);

    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.upload-video .form .client-selection .client-item:hover {
    background: rgba(var(--rgb-dark),0.3);
}

.upload-video .form .client-selection .client-item.selected-client {
    background: rgba(var(--rgb-green),1.0);
}

.upload-video .form .client-selection .client-item.selected-client:hover {
    background: rgba(var(--rgb-green),0.5);
}

.upload-video .form .client-selection .client-item.selected-client p {
    color: var(--color-dark-static);
}

.upload-video .form .client-selection .client-item.selected-client i {
    margin: 0rem 2rem;
    font-size: 1.4rem;
    color: var(--color-dark-static);
}

/* Client Selection Scroll Bar */

.client-selection::-webkit-scrollbar,
.verification-information::-webkit-scrollbar {
    width: 15px;
    height: 20px;
}
  
.client-selection::-webkit-scrollbar-track,
.verification-information::-webkit-scrollbar-track {
    background: rgba(var(--rgb-dark),0.2);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
  
.client-selection::-webkit-scrollbar-thumb,
.verification-information::-webkit-scrollbar-thumb {
        background: var(--color-dark);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        cursor: pointer;
}
  
.client-selection::-webkit-scrollbar-thumb:hover,
.verification-information::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--rgb-dark),0.8);
}


/* Video Information */

.upload-video .form .video-information {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-video .form .input-section {
    position: relative;
    width: 100%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.upload-video .form .input-section label {
    color: var(--color-dark);
    font-size: 1.3rem;
}

.upload-video .form .input-section input,
.upload-video .form .input-section textarea {
    width: 100%;
    min-height: 2rem;
    padding: 1rem;
    
    font-size: 20px;

    /* box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3); */
    border: 2px solid var(--color-dark-static);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

/* Verification Information / Verify */

.verify .verification-information {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.verify .verification-information .information-container {
    width: 90%;
    margin: 1rem;
}

.verify .verification-information .information-container .static {
    font-weight: 600;
    color: rgba(var(--rgb-dark),1.0);
}

.verify .verification-information .information-container .variable {
    font-style: italic;
}

/* Add Client Modal */
.upload-video .add-client-modal {
    width: 90%;
    position: fixed;
    top: 10%;
    left: 50%;
    background: rgba(var(--rgb-light),0.9);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    transition: transform ease-in-out 0.2s;
    -webkit-transition: transform ease-in-out 0.2s;
    -moz-transition: transform ease-in-out 0.2s;
    -ms-transition: transform ease-in-out 0.2s;
    -o-transition: transform ease-in-out 0.2s;
}

.add-client-modal.active {
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
}

.add-client-modal.inactive {
    transform: translate(-50%, -150%);
    -webkit-transform: translate(-50%, -150%);
    -moz-transform: translate(-50%, -150%);
    -ms-transform: translate(-50%, -150%);
    -o-transform: translate(-50%, -150%);
}

.upload-video .add-client-modal .close-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: var(--color-dark);

    cursor: pointer;
}