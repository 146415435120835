.carousel {
    position: relative;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-light-alt);
}

.carousel .carousel-container {
    position: absolute;
    min-height: 100%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1620px) {
    .carousel .carousel-container {
        padding: 0rem 20rem;
    }
}
@media (min-width: 910px) {
    .carousel .carousel-container {
        padding: 0rem 10rem;
    }
}

.carousel .carousel-container .inner-carousel {
    position: absolute;
    width: 100%;
    height: 80%;
    display: flex;
    align-items: flex-start;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
}

.carousel .carousel-container .inner-carousel .carousel-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
    min-height: 100%;
    scroll-snap-align: start;
    padding: 2rem 1rem;
}

.carousel .carousel-container .inner-carousel .carousel-item h1 {
    font-size: 1.8rem;
}
.carousel .carousel-container .inner-carousel .carousel-item p {
    font-size: 1rem;
}

@media (min-width: 1211px) {
    .carousel .carousel-container .inner-carousel .carousel-item {
        padding: 5rem 15rem;
    }
    .carousel .carousel-container .inner-carousel .carousel-item h1 {
        font-size: 2.6rem;
    }
    .carousel .carousel-container .inner-carousel .carousel-item p {
        font-size: 1.8rem;
    }
}

.carousel .carousel-container .inner-carousel .carousel-item .pagination {
    position: absolute;
    bottom: 5px;
    left: 50%;
    font-size: 1rem;

    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);

    color: rgba(var(--rgb-dark),0.3);
}

/* Carousel Scrollbar */
.inner-carousel::-webkit-scrollbar {
    width: 10px;
    height: 20px;
}
  
.inner-carousel::-webkit-scrollbar-track {
    background: rgba(var(--rgb-dark),0.2);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
  
.inner-carousel::-webkit-scrollbar-thumb {
        background: var(--color-dark);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        cursor: pointer;
}
  
.inner-carousel::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--rgb-dark),0.8);
}

.carousel .carousel-container .arrows {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--color-dark);
    font-size: 2rem;
    cursor: pointer;

    z-index: 99;
}