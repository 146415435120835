.settings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background: var(--color-light);
    margin-top: 8vh;
    padding: 2rem 0rem;
}

.settings .address-form {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 1210px) {
    .settings .address-form {
        width: 50vw;
    }
}

.settings .address-form .input-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    margin: 1rem 0rem;
}

.settings .address-form .input-section label {
    font-size: 1.4rem;
    color: var(--color-dark);
}

.settings .address-form .input-section input {
    width: 100%;
    padding: 1rem;
    font-size: 20px;
    border: 2px solid var(--color-dark-static);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.settings .address-form button {
    padding: 0.8rem 1.6rem;
    margin: 1rem;
    background: var(--color-dark);
    color: var(--color-light);
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.settings .address-form button:hover {
    background: rgba(var(--rgb-dark),0.6);
}