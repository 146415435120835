.about {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light);
}

@media (min-width: 1211px) {
    .about {
        padding: 1.5rem 10rem;
    }
}

.about .text {
    max-width: 50%;
    margin: 2rem;
}

.about .image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 50%;
    margin: 1rem;
}

.about .image img {
    width: 70%;
}

@media (max-width: 1210px) {
    .about {
        flex-direction: column-reverse;
        align-items: center;
    }
    .about .text,
    .about .image {
        max-width: 90%;
    }
}