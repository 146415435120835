:root {
  --color-light-static: rgba(248,248,248,1.0);
  --color-dark-static: rgba(19,17,12,1.0);
  
  --rgb-light-static: 248,248,248;
  --rgb-dark-static: 19,17,12;

  --color-light-alt-static: rgba(224,224,224,1.0);
  --rgb-light-alt-static: 224,224,224;

  --color-dark-alt-static: rgba(29,26,19,1.0);
  --rgb-dark-alt-static: 29,26,19;
}

[data-theme='light'] {
  --color-light: rgba(248,248,248,1.0);
  --rgb-light: 248,248,248;

  --color-light-alt: rgba(224,224,224,1.0);
  --rgb-light-alt: 224,224,224;
  
  --color-dark: rgba(19,17,12,1.0);
  --rgb-dark: 19,17,12;

  --color-dark-alt: rgba(29,26,19,1.0);
  --rgb-dark-alt: 29,26,19;

  --color-green: rgba(85,204,61,1.0);
  --rgb-green: 85,204,61;
}

[data-theme='dark'] {
  --color-light: rgba(19,17,12,1.0);
  --rgb-light: 19,17,12;
  
  --color-light-alt: rgba(29,26,19,1.0);
  --rgb-light-alt: 29,26,19;

  --color-dark: rgba(248,248,248,1.0);
  --rgb-dark: 248,248,248;
  
  --color-dark-alt: rgba(224,224,224,1.0);
  --rgb-dark-alt: 224,224,224;
  
  --color-green: rgba(85,204,61,1.0);
  --rgb-green: 85,204,61;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.App {
  background: var(--color-light);
}

.font-title {
  font-family: 'Staatliches', cursive;
  letter-spacing: 0.15rem;
}

.page {
  min-height: 100vh;
  margin-top: 8vh;
}

h1 {
  font-size: 3rem;
  color: var(--color-dark);
}

p {
  font-size: 1.4rem;
  letter-spacing: 0.08rem;
  line-height: 2rem;
  color: rgba(var(--rgb-dark),0.6);
}

a {
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: var(--color-dark);
  cursor: default;
}

.disabled-link {
  cursor: not-allowed !important;
  opacity: 0.3;
}