.edit-about-page {
    min-height: 100vh;
    background: var(--color-light);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.edit-about-page .input-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80vh;
    margin: 1rem 0rem;
}

.edit-about-page .input-section label {
    font-size: 1.4rem;
    color: var(--color-dark);
}

.edit-about-page .input-section input,
.edit-about-page .input-section textarea {
    width: 100%;
    padding: 1rem;
    font-size: 20px;
    border: 2px solid var(--color-dark-static);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.edit-about-page .buttons {
    margin: 1rem 0rem;
}

.edit-about-page .buttons button {
    padding: 0.8rem 1.6rem;
    margin: 0rem 2rem;
    background: var(--color-dark);
    color: var(--color-light);
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.edit-about-page .buttons button:hover {
    background: rgba(var(--rgb-dark),0.6);
}