.navbar {
    position: fixed;
    top: 0;
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: var(--color-light);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    z-index: 999;
}

.navbar .navbar-fill {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 1210px) {
    .navbar .navbar-fill {
        display: none;
    }
}

.navbar .navbar-fill i {
    margin: 2rem;
    color: var(--color-dark);
    cursor: pointer;
}

.navbar .navbar-fill p {
    font-size: 0.8rem;
    color: rgba(var(--rgb-dark),0.6);
}

.navbar .navbar-fill p span {
    font-weight: 600;
}

.navbar .logo {
    width: 33%;
    object-fit: contain;
}

@media (max-width: 1210px) {
    .navbar .logo {
        margin: 1rem;
        width: 25%;
    }
}

.navbar ul {
    height: 100%;
    width: 33%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar ul li {
    list-style-type: none;
    margin: 1rem;
    color: var(--color-dark);
    cursor: pointer;
}

@media (min-width: 1211px) {
    .navbar ul li:last-child {
        margin-right: 5rem;
    }
}

.admin-menu {
    position: fixed;
    top: 5rem;
    left: 2rem;
    padding: 2rem;
    
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.4);
    background: var(--color-light);
    color: var(--color-dark);
    
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    
    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;

    z-index: 99;
}

.admin-menu.active {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
}

.admin-menu.inactive {
    transform: translateY(-150%);
    -webkit-transform: translateY(-150%);
    -moz-transform: translateY(-150%);
    -ms-transform: translateY(-150%);
    -o-transform: translateY(-150%);
}

@media only screen and (max-width: 1210px) {
    .admin-menu {
        display: none;
    }
}

.admin-menu ul {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.admin-menu ul li {
    list-style-type: none;
    margin: 1rem;
    cursor: pointer;
}

.admin-menu ul hr {
    margin: 1rem 0rem;
    width: 100%;
}

.admin-menu ul .logout {
    /* margin-top: auto; */
    justify-self: flex-end;
}

