.header {
    max-width: 100vw;
    background: var(--color-light);
}

.header .header-video {
    width: 100%;
}

/* @media (max-width: 1210px) {
    .header {
        display: none;
    }
} */