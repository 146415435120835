.cases {
    margin-top: 5rem;
    min-height: 100vh;
    background: var(--color-light);
    padding: 2rem;
}

.cases .title {
    text-align: center;
}

.cases .cases-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.cases .cases-container .case-item {
    position: relative;
    width: 30%;
    min-height: 600px;
    margin: 5rem;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.3);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
}

@media (max-width: 1210px) {
    .cases .cases-container .case-item {
        width: 100%;
        padding: 1rem;
        margin: 1rem;
        min-height: 700px;
    }
}
.cases .cases-container .case-item:hover {
    /* border: 2px solid transparent; */
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.3);
    background: rgba(var(--rgb-dark),0.15);
    cursor: pointer;
}

@media (max-width: 1210px) {
    .cases .cases-container .case-item {
        text-align: left;
    }
}

.cases .cases-container .case-item h1 {
    width: 100%;
    font-size: 2rem;
}

.cases .cases-container .case-item p {
    width: 100%;
    margin: 1rem 0rem;
}

.cases .cases-container .case-item iFrame {
    margin: 1rem 0rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.cases .cases-container .case-item p.item-description {
    position: relative;
    font-size: 1.2rem;
    padding: 0.5rem;
    overflow: hidden;
}

.cases .cases-container .case-item button {
    position: absolute;
    bottom: 2rem;
    /* margin: 0.6rem; */
    border: none;
    padding: 0.8rem 1.6rem;
    background: rgba(var(--rgb-dark),1.0);
    color: var(--color-light);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.cases .cases-container .case-item button:hover {
    background: rgba(var(--rgb-dark-alt),0.7);
    color: var(--color-light);
}

.cases .cases-container .case-item button.edit-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cases .show-more-videos {
    text-align: center;
}

.cases .show-more-videos button {
    background: var(--color-light);
    color: var(--color-dark);
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid var(--color-dark);
    cursor: pointer;
}

.cases .show-more-videos button:hover {
    color: rgba(var(--rgb-dark-alt),0.5);
    border-bottom: 1px solid rgba(var(--rgb-dark-alt),0.5);
}

/* UPDATE VIDEO MODAL */

.cases .update-video-modal {
    width: 80%;
    position: fixed;
    top: 20%;
    left: 50%;

    padding: 3rem;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    background: var(--color-light);

    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.cases .update-video-modal.inactive {
    display: none;
}

.cases .update-video-modal .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cases .update-video-modal .form h2 {
    color: var(--color-dark);
}

.cases .update-video-modal .input-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.cases .update-video-modal .input-section label {
    font-size: 1.5rem;
    color: var(--color-dark);
}

.cases .update-video-modal .input-section input,
.cases .update-video-modal .input-section textarea {
    width: 100%;
    min-height: 2rem;
    padding: 1rem;
    margin: 0.5rem;
    font-size: 20px;
    border: 2px solid var(--color-dark-static);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.cases .update-video-modal .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: var(--color-dark);
    cursor: pointer;
}

.cases .update-video-modal .buttons button {
    margin: 1rem;
    padding: 0.7rem 1.4rem;
    background: var(--color-dark);
    color: var(--color-light);
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.cases .update-video-modal .buttons button:hover {
    background: rgba(var(--rgb-dark-alt),0.7);
}