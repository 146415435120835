.logo-display {
    position: relative;
    background: var(--color-light-alt);
    min-height: 30vh;
}

.logo-display .logo-list {
    position: relative;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0rem 10rem;
}

.logo-display .logo-list .logo {
    margin: 1rem;
    width: 120px;
}

@media only screen and (max-width: 1210px) {
    .logo-display .logo-list {
        padding: 0;
        flex-direction: column;
    }
    .logo-display .logo-list .logo {
        margin: 1rem 0rem;
    }
}