.login {
    min-height: 100vh;
    background: var(--color-light);

    display: flex;
    justify-content: center;
    align-items: center;
}

.login .input-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: rgba(var(--rgb-dark), 0.5);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

@media only screen and (min-width: 910px) {
    .login .input-container {
        width: 40%;
    }
}

.login .input-container .input-component {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login .input-container .input-component label {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
}

.login .input-container .input-component input {
    width: 100%;
    height: 4rem;
    padding: 1rem;
    margin: 1rem 0rem;
    border: none;
    font-size: 20px;

    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.login .input-container .input-component .input-password {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .input-container .input-component input.password {
    width: 80%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.login .input-container .input-component .show-password-toggle {
    width: 20%;
    height: 4rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    cursor: pointer;
}

.login .input-container .input-component .show-password-toggle:hover {
    opacity: 0.7;
}

.login .input-container button {
    padding: 1rem 2rem;
    border: none;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    background: var(--color-dark);
    color: var(--color-light);

    cursor: pointer;
}

.login .input-container button:hover {
    background: var(--color-light);
    color: var(--color-dark);
}