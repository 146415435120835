.edit-page-content {
    min-height: 100vh;
    background: var(--color-light);
    margin-top: 8vh;
    padding: 5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.edit-page-content .selection {
    height: 30vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.edit-page-content .selection .item {
    width: 300px;
    margin: 4rem;
    padding: 2rem 4rem;
    background: var(--color-dark);
    color: var(--color-light);
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.edit-page-content .selection .item:hover {
    background: rgba(var(--rgb-dark-alt),0.8);
}